<template>
    <div class="app-container">
        <el-form :model="form" label-width="80px">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="账号状态">
                        <el-select v-model="form.status">
                            <el-option :value="item.value" :label="item.label" v-for="item in baseInfo.statusList"
                                       :key="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="baseInfo.companyList && baseInfo.companyList.length > 0">
                    <el-form-item label="公司">
                        <el-select v-model="form.companyId" clearable @change="handleCompanyChange">
                            <el-option :value="item.id" :label="item.name" v-for="item in baseInfo.companyList"
                                       :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="所属部门">
                        <el-cascader v-model="form.deptList" :options="levelTree" :props="treeProps"
                                     clearable></el-cascader>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="关键字">
                        <el-input v-model="form.keywords" placeholder="请输入巡检人员姓名/手机号码" clearable
                                  @keyup.enter.native="getFrontUser" style="width: 200px;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="">
                        <el-button type="primary" @click="getFrontUser(1)">查询</el-button>
                        <el-button @click="reset">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            <div style="margin-bottom: 20px;">
                <el-button type="primary" @click="handleAdd" icon="el-icon-plus">新增人员</el-button>
                <el-button @click="handleExport">导出列表</el-button>
            </div>
        </el-form>
        <el-table :data="frontList" border style="width: 100%">
            <el-table-column prop="name" align="center" label="姓名" width="150px"></el-table-column>
            <el-table-column prop="mobile" align="center" label="手机号码" width="150px"></el-table-column>
            <el-table-column prop="companyName" align="center" label="所属公司" width="200px"></el-table-column>
            <el-table-column prop="deptName" align="center" label="所属部门" width="250px"></el-table-column>
            <el-table-column prop="status" align="center" label="账号状态" width="100px">
                <template slot-scope="scope">{{scope.row.status === 1 ? '正常' : '冻结'}}</template>
            </el-table-column>
            <el-table-column align="center" label="操作" fixed="right" width="350px">
                <template slot-scope="scope">
                    <el-button type="primary" @click="handleEdit(scope.row)">修改</el-button>
                    <el-button type="primary" v-if="scope.row.status === 1" @click="handleFreeze(scope.row)">冻结
                    </el-button>
                    <el-button type="success" v-else @click="handleFreeze(scope.row)">解冻</el-button>
                    <el-button type="success" @click="handleRelate(scope.row.id)">关联设备</el-button>
                    <el-button type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="total, prev, pager, next, jumper" :page-size="20" :total="frontTotal"
                       :current-page="form.pageNum"
                       @current-change="currentChange" style="margin: 20px 0;"/>
        <!-- 新增人员 -->
        <el-dialog title="新增人员" :visible.sync="addVisible" width="600px">
            <el-form ref="addForm" :model="addForm" label-width="120px" :rules="rules">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="addForm.name" placeholder="请输入姓名" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="所属公司" prop="companyId">
                    <el-select v-model="addForm.companyId" clearable @change="handleCompanyChange2">
                        <el-option :value="item.id" :label="item.name" v-for="item in baseInfo.companyList"
                                   :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属部门" prop="deptList">
                    <el-cascader v-model="addForm.deptList" :options="levelTree2" :props="treeProps"
                                 clearable></el-cascader>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input v-model="addForm.mobile" placeholder="请输入手机号" type="tel" style="width: 300px;"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleAddSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改人员 -->
        <el-dialog title="修改人员" :visible.sync="editVisible" width="600px">
            <el-form ref="editForm" :model="editForm" label-width="120px" :rules="rules">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="editForm.name" placeholder="请输入姓名" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="所属公司" prop="companyId">
                    <el-select v-model="editForm.companyId" clearable @change="handleCompanyChange3">
                        <el-option :value="item.id" :label="item.name" v-for="item in baseInfo.companyList"
                                   :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属部门" prop="deptList">
                    <el-cascader v-model="editForm.deptList" :options="levelTree2" :props="treeProps"
                                 clearable></el-cascader>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input v-model="editForm.mobile" placeholder="请输入手机号" type="tel"
                              style="width: 300px;"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleEditClose">取 消</el-button>
                <el-button type="primary" @click="handleEditSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 关联设备 -->
        <el-dialog title="关联设备" append-to-body :visible.sync="relateVisible" width="800px">
            <el-tabs v-model="cardName" @tab-click="getBindDeviceList(1)">
                <el-tab-pane label="一级权限设备" name="1">
                    <div style="margin-bottom: 20px;">
                        <el-button type="primary" @click="handleAddDevice" icon="el-icon-plus">添加设备</el-button>
                    </div>
                    <el-table :data="firstList" border style="width: 100%">
                        <el-table-column type="index" align="center" width="80px"></el-table-column>
                        <el-table-column prop="deviceCode" align="center" label="设备编码"></el-table-column>
                        <el-table-column align="center" label="操作" width="150px">
                            <template slot-scope="scope">
                                <el-button type="danger" @click="deleteDevice(scope.row.id)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="二级权限设备" name="2">
                    <div style="margin-bottom: 20px;">
                        <el-button type="primary" @click="handleAddDevice" icon="el-icon-plus">添加设备</el-button>
                    </div>
                    <el-table :data="secondList" border style="width: 100%">
                        <el-table-column type="index" align="center" width="80px"></el-table-column>
                        <el-table-column prop="deviceCode" align="center" label="设备编码"></el-table-column>
                        <el-table-column align="center" label="操作" width="150px">
                            <template slot-scope="scope">
                                <el-button type="danger" @click="deleteDevice(scope.row.id)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="三级权限设备" name="3">
                    <div style="margin-bottom: 20px;">
                        <el-button type="primary" @click="handleAddDevice" icon="el-icon-plus">添加设备</el-button>
                    </div>
                    <el-table :data="thirdList" border style="width: 100%">
                        <el-table-column type="index" align="center" width="80px"></el-table-column>
                        <el-table-column prop="deviceCode" align="center" label="设备编码"></el-table-column>
                        <el-table-column align="center" label="操作" width="150px">
                            <template slot-scope="scope">
                                <el-button type="danger" @click="deleteDevice(scope.row.id)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
            <el-pagination background layout="total, prev, pager, next, jumper" :page-size="20" :total="deviceTotal"
                           :current-page="devicePage"
                           @current-change="currentChangeDevice" style="margin: 20px 0;"/>
            <div slot="footer" class="dialog-footer">
                <el-button @click="relateVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
        <!-- 添加设备 -->
        <el-dialog title="添加设备" append-to-body :visible.sync="addDeviceVisible" width="600px">
            <el-form ref="addDeviceForm" :model="addDeviceForm" label-width="120px" :rules="addDeviceRules">
                <el-form-item label="设备权限">
                    <el-select v-model="addDeviceForm.rootLevel" placeholder="请选择设备权限" disabled clearable
                               style="width: 300px;">
                        <el-option :value="item.value" :label="item.label" :key="item.value"
                                   v-for="item in deviceRoots"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="deviceId" label="设备编号">
                    <el-select v-model="addDeviceForm.deviceId" placeholder="请选择设备编号" style="width: 300px;">
                        <el-option :value="item.id" :label="item.code" :key="item.value"
                                   v-for="item in unBindDevices"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDeviceVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleAddDeviceSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
  addFrontUserApi,
  bindUserDeviceApi,
  delDeviceBindApi,
  frontChangeUserStatusApi,
  frontUserExportApi,
  frontUserSearchApi,
  getBindDeviceListApi,
  getFrontManagerBaseApi,
  getUnBindDeviceListApi,
  modifyFrontUserApi
} from '@/api'

export default {
        name: 'staff',
        data() {
            return {
                baseInfo: {},
                form: {
                    status: '',
                    keywords: '',
                    companyId: '',
                    deptList: undefined,
                    pageNum: 1
                },
                treeProps: {
                    expandTrigger: 'hover',
                    value: 'id'
                },
                levelTree: undefined,
                levelTree2: undefined,
                frontList: [],
                frontTotal: 0,
                addVisible: false,
                addForm: {
                    name: undefined,
                    companyId: undefined,
                    deptList: undefined,
                    mobile: undefined
                },
                editVisible: false,
                editForm: {
                    id: undefined,
                    name: undefined,
                    companyId: undefined,
                    deptList: undefined,
                    mobile: undefined
                },
                rules: {
                    name: [
                        {required: true, message: '请输入姓名', trigger: 'blur'}
                    ],
                    companyId: [
                        {required: true, message: '请选择所属公司', trigger: 'blur'}
                    ],
                    deptList: [
                        {required: true, message: '请选择所属部门', trigger: 'blur'}
                    ],
                    mobile: [
                        {required: true, message: '请输入手机号', trigger: 'blur'}
                    ]
                },
                relateVisible: false,
                cardName: '1',
                firstList: [],
                secondList: [],
                thirdList: [],
                devicePage: 1,
                deviceTotal: 0,
                addDeviceVisible: false,
                addDeviceForm: {
                    deviceId: undefined,
                    rootLevel: undefined,
                    userId: undefined
                },
                unBindDevices: [],
                deviceRoots: [{
                    label: '一级权限',
                    value: '1'
                }, {
                    label: '二级权限',
                    value: '2'
                }, {
                    label: '三级权限',
                    value: '3'
                }],
                addDeviceRules: {
                    deviceId: [
                        {required: true, message: '请选择设备', trigger: 'blur'}
                    ]
                }
            }
        },
        mounted() {
            this.getBaseInfo()
            this.getFrontUser()
        },
        methods: {
            getBaseInfo() {
                getFrontManagerBaseApi().then(res => {
                    if (res) {
                        this.baseInfo = res.data
                    }
                })
            },
            getFrontUser(page) {
                if (page) {
                    this.form.pageNum = 1
                }
                frontUserSearchApi(this.form).then(res => {
                    if (res) {
                        this.frontList = res.data.list
                        this.frontTotal = res.data.totalPage
                    }
                })
            },
            currentChange(page) {
                this.form.pageNum = page
                this.getFrontUser()
            },
            reset() {
                this.form['status'] = ''
                this.form['keywords'] = ''
                this.form['companyId'] = undefined
                this.form['deptList'] = undefined
            },
            handleCompanyChange() {
                this.levelTree = this.baseInfo['levelTree'][this.form.companyId]
            },
            handleCompanyChange2() {
                this.levelTree2 = this.baseInfo['levelTree'][this.addForm.companyId]
            },
            handleCompanyChange3() {
                this.levelTree2 = this.baseInfo['levelTree'][this.editForm.companyId]
            },
            handleFreeze(row) {
                this.$confirm('确认' + (row.status === 1 ? '冻结?' : '解冻?'), '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    frontChangeUserStatusApi({
                        id: row.id,
                        status: row.status === 1 ? 2 : 1
                    }).then(res => {
                        if (res) {
                            this.$message.success('操作成功!')
                            this.getFrontUser()
                        }
                    })
                })
            },
            handleDelete(id) {
                this.$confirm('确认删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    frontChangeUserStatusApi({
                        id,
                        status: 3
                    }).then(res => {
                        if (res) {
                            this.$message.success('删除成功!')
                            this.getFrontUser(1)
                        }
                    })
                })
            },
            handleExport() {
                frontUserExportApi({
                    companyId: this.form.companyId,
                    deptList: this.form.deptList,
                    keywords: this.form.keywords,
                    status: this.form.status
                }).then(res => {
                    if (res) {
                        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = '巡检人员数据导出'
                        link.click()
                    }
                })
            },
            handleAdd() {
                this.addVisible = true
                this.$refs['addForm'].resetFields()
            },
            handleAddSubmit() {
                this.$refs['addForm'].validate(valid => {
                    if (valid) {
                        addFrontUserApi(this.addForm).then(res => {
                            if (res) {
                                this.$message.success('添加成功!')
                                this.getFrontUser()
                                this.addVisible = false
                            }
                        })
                    }
                })
            },
            handleEdit(row) {
                console.log(row)
                this.editForm['id'] = row.id
                this.editForm['name'] = row.name
                this.editForm['companyId'] = row.companyId
                this.editForm['deptList'] = [row.level2Id, row.level3Id, row.level4Id]
                this.editForm['mobile'] = row.mobile
                this.handleCompanyChange3()
                this.editVisible = true
            },
            handleEditClose() {
                this.$refs['editForm'].resetFields()
                this.editVisible = false
            },
            handleEditSubmit() {
                this.$refs['editForm'].validate(valid => {
                    if (valid) {
                        modifyFrontUserApi(this.editForm).then(res => {
                            if (res) {
                                this.$message.success('修改成功!')
                                this.getFrontUser()
                                this.editVisible = false
                            }
                        })
                    }
                })
            },
            handleRelate(frontUserId, cardName, page) {
                if (frontUserId) {
                    this.frontUserId = frontUserId
                }
                if (cardName) {
                    this.cardName = cardName
                } else {
                    this.cardName = '1'
                }
                this.relateVisible = true
                this.getBindDeviceList(page)
            },
            currentChangeDevice(page) {
                this.devicePage = page
                this.getBindDeviceList()
            },
            getBindDeviceList(page) {
                if (page) {
                    this.devicePage = 1
                }
                getBindDeviceListApi({
                    frontUserId: this.frontUserId,
                    pageNum: this.devicePage,
                    rootLevel: this.cardName
                }).then(res => {
                    switch (this.cardName) {
                        case '1':
                            this.firstList = res.data.list
                            break
                        case '2':
                            this.secondList = res.data.list
                            break
                        case '3':
                            this.thirdList = res.data.list
                            break
                        default:
                            break
                    }
                    this.deviceTotal = res.data.totalPage
                })
            },
            deleteDevice(id) {
                this.$confirm('确认删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delDeviceBindApi({id}).then(res => {
                        if (res) {
                            this.$message.success('删除成功!')
                            this.handleRelate(this.frontUserId, this.cardName, 1)
                        }
                    })
                })
            },
            handleAddDevice() {
                this.addDeviceVisible = true
                this.addDeviceForm['rootLevel'] = this.cardName
                this.addDeviceForm['deviceId'] = undefined
                this.addDeviceForm['userId'] = this.frontUserId
                getUnBindDeviceListApi({
                    frontUserId: this.frontUserId,
                    rootLevel: this.cardName
                }).then(res => {
                    if (res) {
                        this.unBindDevices = res.data
                    }
                })
            },
            handleAddDeviceSubmit() {
                this.$refs['addDeviceForm'].validate(valid => {
                    if (valid) {
                        bindUserDeviceApi(this.addDeviceForm).then(res => {
                            if (res) {
                                this.$message.success('添加成功!')
                                this.handleRelate(this.frontUserId, this.cardName)
                                this.addDeviceVisible = false
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
